import React from 'react';
import { graphql } from 'gatsby';

import PageHeader from '../components/PageHeader';
import Content from '../components/Content';
import Layout from '../components/Layout';

// Export Template for use in CMS preview
export const InfoPageTemplate = ({ title, subtitle, body }) => (
  <main className="Info">
    <PageHeader title={title} subtitle={subtitle} />

    <Content source={body} className="container mb-5" />
  </main>
);

const InfoPage = ({ data: { page } }) => (
  <Layout meta={page.frontmatter.meta || false} title={page.frontmatter.title || false}>
    <InfoPageTemplate {...page.frontmatter} body={page.html} />
  </Layout>
);
export default InfoPage;

export const pageQuery = graphql`
  query InfoPage($id: String!) {
    page: markdownRemark(id: { eq: $id }) {
      ...Meta
      html
      frontmatter {
        title
        subtitle
      }
    }
  }
`;
